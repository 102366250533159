import {
    runLineAnimationForwards, runCircleAnimationBackwards,
    runCircleAnimationForwards, runLineAnimationBounce, runLineAnimationBounceBack,
} from './animations/linesAnimations';

import { isDOMElementInViewport } from './util';

const JobsPillarsTile = (() => {
    let animationType;

    function checkContentOfPillarItem() {
        var journeyItems = document.querySelectorAll('.card--pillars .card__item');

        journeyItems.forEach(function(item) {
            if(!item.hasChildNodes()) {
                item.remove();
            }
        });
    }

    function isPathsVisible() {
        let svgChildren;
        let pathsVisible;

        if (animationType === 'Circle') {
            svgChildren = document.getElementById('circleAnimation_pillarsTile').childNodes;
        } else if (animationType === 'RoundedLinesTeal') {
            svgChildren = document.getElementById('roundedLinesTealAnimation_pillarsTile').childNodes;
        } else if (animationType === 'WavesOrange') {
            svgChildren = document.getElementById('wavesOrangeAnimation_pillarsTile').childNodes;
        }

        svgChildren.forEach(el => {
            if (el.style && el.style.opacity === '1') {
                pathsVisible = true;
            } else if (el.style && el.style.opacity === '0') {
                pathsVisible = false;
            }
        });
        return pathsVisible;
    }

    function runAnimations() {
        const membershipTiles = document.querySelectorAll('.card__item--animation');


        membershipTiles.forEach((tile) => {
            if (isDOMElementInViewport(tile)) {
                tile.childNodes.forEach(child => {
                    if (child.className && child.className.includes('card__inner')) {
                        animationType = child.dataset.animationtype;
                    }
                });

                isPathsVisible();


                if (animationType === 'Circle' && !isPathsVisible()) {
                    runCircleAnimationForwards('#outerCircle_pillarsTile', '#innerCircle_pillarsTile');

                    // Hover state
                    tile.addEventListener('mouseenter', function () {
                        runCircleAnimationBackwards('#outerCircle_pillarsTile', '#innerCircle_pillarsTile');
                    });
                    tile.addEventListener('mouseleave', function () {
                        runCircleAnimationForwards('#outerCircle_pillarsTile', '#innerCircle_pillarsTile');
                    });
                }
                else if (animationType === 'RoundedLinesTeal' && !isPathsVisible()) {
                    runLineAnimationForwards('#roundedLineTeal1_pillarsTile');
                    runLineAnimationForwards('#roundedLineTeal2_pillarsTile');

                    // Hover state
                    tile.addEventListener('mouseenter', function () {
                        runLineAnimationBounce('#roundedLineTeal1_pillarsTile');
                        runLineAnimationBounce('#roundedLineTeal2_pillarsTile');
                    });
                    tile.addEventListener('mouseleave', function () {
                        runLineAnimationBounceBack('#roundedLineTeal1_pillarsTile');
                        runLineAnimationBounceBack('#roundedLineTeal2_pillarsTile');
                    });
                }
                else if (animationType === 'WavesOrange' && !isPathsVisible()) {
                    runLineAnimationForwards('#waveOrangeLine1_pillarsTile');
                    runLineAnimationForwards('#waveOrangeLine2_pillarsTile');

                    // Hover state
                    tile.addEventListener('mouseenter', function () {
                        runLineAnimationBounce('#waveOrangeLine1_pillarsTile');
                        runLineAnimationBounce('#waveOrangeLine2_pillarsTile');
                    });
                    tile.addEventListener('mouseleave', function () {
                        runLineAnimationBounceBack('#waveOrangeLine1_pillarsTile');
                        runLineAnimationBounceBack('#waveOrangeLine2_pillarsTile');
                    });
                }
            }
        });

    }

    function checkTwoTiles () {
        var cardList = document.querySelector('.js-card-list');

        cardList.childNodes.length == 2 ? cardList.classList.add('card__list--two-items') : '';
    }

    return {
        init() {
            checkContentOfPillarItem();
            runAnimations();
            checkTwoTiles();
        },
        scroll() {
            runAnimations();
        },
    };
})();

export default (() => {
    $(document).ready(JobsPillarsTile.init);

    $(window).on('scroll', JobsPillarsTile.scroll);
})();
